import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['video', 'cover']

  play() {
    this.coverTarget.remove()
    this.videoTarget.play()
  }
}
