import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['open', 'close', 'menu']

  toggle() {
    this.openTarget.classList.toggle('hidden')
    this.closeTarget.classList.toggle('hidden')
    this.menuTarget.classList.toggle('hidden')
  }
}
