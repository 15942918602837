import { Controller } from 'stimulus'

import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

export default class extends Controller {
  static targets = ['pagination', 'container']
  static values = { count: Number }

  connect() {
    const slider = new Swiper(this.containerTarget, {
      loop: true,
      init: false,
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
      autoplay: {
        delay: 4000,
      },
    })

    if (this.countValue > 1) slider.init()
  }
}
